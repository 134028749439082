export const ApiEndPoint = {
	// login
	loginApi: 'services/AdminApp/SignIn/SignInManually',
	refreshTokenApi: 'services/AdminApp/SignIn/RefreshToken',
	// organizations
	organizationListApi: 'services/AdminApp/ServiceProvider/ServiceProviders',
	pendingRejectedProfileApi:
		'services/AdminApp/ServiceProvider/GetRejectedSPProfile',
	acceptOrganizationApi: 'services/AdminApp/ServiceProvider/Accept',
	rejectOrganizationApi: 'services/AdminApp/ServiceProvider/Reject',
	organizationProfileApi:
		'services/AdminApp/ServiceProvider/GetAcceptedSPProfile',
	organizationBranchInformationApi: 'services/AdminApp/Branch/GetDetails',
	getBusinessDetailsApi: 'services/AdminApp/ServiceProvider/GetBusinessDetails',
	updateBranchLocationApi: 'services/AdminApp/Branch/UpdateLocation',
	updateSocialAccountsApi: 'services/AdminApp/Branch/UpdateSocialAccounts',
	updateWorkingHoursApi: 'services/AdminApp/Branch/UpdateWorkingHours',
	validateWorkingHoursApi: 'services/AdminApp/Branch/validateWorkingHourPerDay',
	UpdateBusinessDetailsApi:
		'services/AdminApp/ServiceProvider/UpdateBusinessDetails',
	checkBussinessNameApi: 'services/AdminApp/Tenant/CheckIfBusinessNameExists',
	UploadLogoImageApi: 'Image/UploadLogoImage',
	UploadCommercialRegistrationImageApi:
		'Image/UploadCommercialRegistrationImage',
	UploadMaroofImageApi: 'Image/UploadMaroofImage',
	updateBranchBasicInformationApi: 'services/AdminApp/Branch/UpdateBasicInfo',
	updateBranchContactInformationApi:
		'services/AdminApp/Branch/UpdateContactInfo',
	updateTargetAudienceApi: 'services/AdminApp/Branch/UpdateTargetAudience',
	UploadSliderImageApi: 'Image/UploadSliderImage',
	updateSliderImageApi: 'services/AdminApp/BranchGallery/SetSlider',
	Activate_Deactivate_OrganizationBranchApi:
		'services/AdminApp/Branch/UpdateStatus',
	saveBranchSettingsApi: 'services/AdminApp/Branch/UpdateSettings',
	serviceProversCountApi:
		'services/AdminApp/ServiceProvider/ServiceProvidersCount',
	GetCityByCountry: 'services/AdminApp/City/GetByCountry',
	GetIsNameUnique: 'services/AdminApp/Branch/IsNameUnique',
	IsMobileNumberUnique: 'services/AdminApp/Branch/IsMobileNumberUnique',
	IsLandLineUnique: 'services/AdminApp/Branch/IsLandLineUnique',
	BranchValidateUniqueNameApi: 'services/AdminApp/Branch/ValidateUniqueName',
	BranchValidateUniqueContactInfoApi:
		'services/AdminApp/Branch/ValidateUniqueContactInfo',
	CheckCanInactiveDayApi: 'services/AdminApp/Branch/GetWorkDayHasBooking',
	UploadGalleryVideoApi: 'Video/UploadGalleryVideo',
	UploadVideoThumbnailApi: 'Image/UploadGalleryVideoThumbnail',
	UploadGlleyImage: 'Image/UploadGalleryImage',
	AddToGalleryApi: 'services/AdminApp/BranchGallery/AddToGallery',
	BranchGalleryApi: 'services/AdminApp/BranchGallery/SearchAll',
	DeleteGalleryItem: 'services/AdminApp/BranchGallery/DeleteGalleryItem',
	SetSliderApi: 'services/AdminApp/BranchGallery/SetDefaultSliderImage',
	// Chat
	OrganizationChatListApi: 'Chat/GetAdminChatThreads',
	OrganizationChatDetailsApi: 'Chat/GetAdminChatThreadMessages',
	OrganizationChatBooking:
		'services/AdminApp/Appointment/GetChatClientBranchActiveAppointments',
	// services
	getServicesListApi: 'services/AdminApp/Service/GetBranchServices',
	getCategoriesApi: 'services/AdminApp/Category/GetBranchCategorySubCategory',
	getServiceTemplateDataApi:
		'services/AdminApp/TemplateService/GetAvailableForBranch',
	getServiceDetailsApi: 'services/AdminApp/Service/GetDetails',
	AddServiceFromTemplateApi: 'services/AdminApp/Service/AddServiceFromTemplate',
	DeleteServiceApi: 'services/AdminApp/Service/Delete',
	DeleteFlag: 'services/AdminApp/Service/GetBranchServices',
	AddServiceDiscoundApi: 'services/AdminApp/Service/UpdateDiscountAmount',
	DeleteServiceDiscoundApi: 'services/AdminApp/Service/UpdatePrice',
	BranchPriceRange: 'services/AdminApp/Service/GetBranchServicePriceRange',
	BranchCategories: 'services/AdminApp/Category/GetBranchCategories',
	GetFilteredBranchServices:
		'services/AdminApp/Service/GetFilteredBranchServices',

	// shared component
	getCountryCodeApi: 'services/ServiceProviderApp/Country/GetCountries',
	getServiceProviderTypesApi:
		'services/ServiceProviderApp/ServiceProvider/GetServiceProviderTypes',
	UpdateStatusApi: 'services/AdminApp/Service/UpdateStatus',
	UpdateData: 'services/AdminApp/Service/UpdateData',
	UpdatePrice: 'services/AdminApp/Service/UpdatePrice',
	UpdateDiscount: 'services/AdminApp/Service/UpdateDiscountAmount',

	// Booking component

	SearchAllBookingsApi: 'services/AdminApp/Appointment/SearchAll',
	SearchAllTabCountApi: 'services/AdminApp/Appointment/SearchAllTabCount',
	GetByCountryApi: 'services/AdminApp/ServiceProvider/GetByCountry',
	GetDetailsApi: 'services/AdminApp/Appointment/GetDetails',
	CancelBookingApi: 'services/AdminApp/Appointment/Cancel',
	ConfirmBookingApi: 'services/AdminApp/Appointment/Confirm',
	CompleteBookingApi: 'services/AdminApp/Appointment/Complete',
	MarkDidNotShowUpBookingApi: 'services/AdminApp/Appointment/MarkDidNotShowUp',
	UpdatePaymentMethodApi: 'services/AdminApp/Appointment/UpdatePaymentMethod',
	SearchByServiceProviderApi:
		'services/AdminApp/Branch/SearchByServiceProvider',
	GetUpChargedSummeryApi:
		'services/AdminApp/Appointment/GetUpchargeUpdateSummery',
	CompleteBookingWithPriceRangeApi:
		'services/AdminApp/Appointment/CompleteUpChargedAppointment',
	UpdateBookingRatingStatusApi:
		'services/AdminApp/AppointmentRating/UpdateRatingStatus',
	SendOnlineUpcharegeRequestApi:
		'services/AdminApp/Appointment/SendOnlineUpchargeRequest',

	// Notification component
	GetAllNotificationApi: 'services/AdminApp/Notifications/GetAll',
	MarkAsReadApi: 'services/AdminApp/Notifications/IsRead',

	//OFFERS
	OffersListApi: 'services/AdminApp/Offer/SearchAll',
	DeleteOfferApi: 'services/AdminApp/Offer/Delete',
	UpdateOfferStatusApi: 'services/AdminApp/Offer/UpdateStatus',
	GetPromoCodeApi: 'services/AdminApp/Offer/GetPromoCode',
	UploadOfferImageApi: 'Image/UploadOfferImage',
	SearchPromoCodeServiceProvidersApi:
		'services/AdminApp/ServiceProvider/SearchPromoCodeServiceProviders',
	AddPromoCodeApi: 'services/AdminApp/Offer/AddPromoCode',
	UpdatePromocodeBasicDataApi:
		'services/AdminApp/Offer/UpdatePromocodeBasicData',
	UpdatePromoCodeCountryAndPricingApi:
		'services/AdminApp/Offer/UpdatePromoCodeCountryAndPricing',
	// CLIENT
	ClientProfileApi: 'services/AdminApp/Client/GetDetails',
	ClientProfileTableApi: 'services/AdminApp/Client/SearchFilterAppointments',
	clientListApi: 'services/AdminApp/Client/SearchAll',
	GetClientWalletDataApi:
		'services/AdminApp/ClientWalletService/GetClientWalletData',
	GetWalletTransactionListApi:
		'services/AdminApp/ClientWalletService/GetWalletTransactionList',
	SearchClientByNameApi: 'services/AdminApp/Client/SearchClientByName',

	// RATING

	GetRatingByBranchIdApi:
		'services/AdminApp/AppointmentRating/GetRatingByBranchId',
	UpdateRatingStatusApi:
		'services/AdminApp/AppointmentRating/UpdateRatingStatus',

	// PACKAGE

	GetPackageDetailsApi: 'services/AdminApp/Package/GetDetails',
	GetPackagesListApi: 'services/AdminApp/Package/SearchAll',
	UploadPackageImageApi: 'Image/UploadPackageImage',
	CreatePackageApi: 'services/AdminApp/Package/Create',
	EditPackageApi: 'services/AdminApp/Package/Update',
	ActivatePackageApi: 'services/AdminApp/Package/ChangePackageActivation',
	ChangePackageBookingApi: 'services/AdminApp/Package/ChangeCanBookFlag',
	DeletePackageApi: 'services/AdminApp/Package/Delete',

	// roles and permissions
	GetPermissionsApi:
		'services/AdminApp/Authorization/GetAllPermissionsByModule',
	GetRolesListApi: 'services/AdminApp/Authorization/GetAllRoles',
	CreatRoleApi: 'services/AdminApp/Authorization/CreateRole',
	UpdateRoleApi: 'services/AdminApp/Authorization/UpdateRole',

	// users
	GetUserDetailsApi: 'services/AdminApp/User/GetDetails',
	UpdateBasicInfoUserApi: 'services/AdminApp/User/UpdateBasicInfo',
	UpdateActiveStatusUserApi: 'services/AdminApp/User/UpdateActiveStatus',
	UpdatePasswordPasswordApi: 'services/AdminApp/User/UpdatePassword',
	DeleteUserApi: 'services/AdminApp/User/Delete',
	CreateUserApi: 'services/AdminApp/User/CreateUser',
	IsNameUniqueApi: 'services/AdminApp/User/IsNameUnique',
	IsEmailAddressUniqueApi: 'services/AdminApp/User/IsEmailAddressUnique',
	IsPhoneNumberUniqueApi: 'services/AdminApp/User/IsPhoneNumberUnique',
	UploadUserImageApi: 'Image/UploadAdminUserImage',
	UserListApi: 'services/AdminApp/User/SearchAll',

	// REFUND
	GetRefundListApi: 'services/AdminApp/AppointmentPaymentRefund/SearchAll',

	GetRefundDetailsApi: 'services/AdminApp/AppointmentPaymentRefund/GetDetails',
	AcceptfundRequestApi:
		'services/AdminApp/AppointmentPaymentRefund/AcceptfundRequest',
	RejectfundRequestApi:
		'services/AdminApp/AppointmentPaymentRefund/RejectfundRequest',

	//  WALLET

	GetTotalBalanceWalletListApi:
		'services/AdminApp/SharedWalletService/GetWalletTotals',
	AddToWalletApi: 'services/AdminApp/SharedWalletService/AddFundToWallet',
	SubtractFromWalletApi:
		'services/AdminApp/SharedWalletService/SubtractFundToClientWallet',
	SpWalletData:
		'services/AdminApp/ServiceProviderWalletService/GetBranchWalletData',
	SpGetWalletTransactionListApi:
		'services/AdminApp/ServiceProviderWalletService/GetWalletTransactionList',
	GetAdminWalletTotalApi:
		'services/AdminApp/SharedWalletService/GetWaletTotals',
	GetClientWalletListApi:
		'services/AdminApp/SharedWalletService/GetClientsWalletList',
	GetSpWalletListApi:
		'services/AdminApp/SharedWalletService/GetBranchesWalletList',
	GetFinanceList: 'services/AdminApp/Finance/GetFinance',

	// STORE
	// brands
	getCities: 'services/AdminApp/City/GetByCountry',
	getBrandsApi: 'services/AdminApp/ProductBrand/SearchAll',
	addBrandApi: 'services/AdminApp/ProductBrand/CreateProductBrand',
	updateBrandStatusApi: 'services/AdminApp/ProductBrand/UpdateProductBrand',
	deleteBrandApi: 'services/AdminApp/ProductBrand/Delete',
	brandIsNameArExistsBeforeApi:
		'services/AdminApp/ProductBrand/IsNameArExistsBefore',
	brandIsNameEnExistsBeforeApi:
		'services/AdminApp/ProductBrand/IsNameEnExistsBefore',
	ChangeBrandActivationStatusApi:
		'services/AdminApp/ProductBrand/ChangeBrandActivationStatus',

	// categories
	getAllProductCategoryApi:
		'services/AdminApp/ProductCategory/GetSubCategoriesList',
	SearchAllCategoriesApi: 'services/AdminApp/ProductCategory/SearchAll',
	CreateProductCategoryApi:
		'services/AdminApp/ProductCategory/CreateProductCategory',
	UpdateProductCategoryApi:
		'services/AdminApp/ProductCategory/UpdateProductCategory',
	deleteProductCategoryApi: 'services/AdminApp/ProductCategory/Delete',
	categoryIsNameEnExistsBeforeApi:
		'services/AdminApp/ProductCategory/IsNameEnExistsBefore',
	categoryIsNameArExistsBeforeApi:
		'services/AdminApp/ProductCategory/IsNameArExistsBefore',
	ChangeCategoryActivationStatusApi:
		'services/AdminApp/ProductCategory/ChangeCategoryActivationStatus',
	UploadProductCategoryImageApi: 'Image/UploadProductCategoryImage',

	// PAYOUT
	GetClientPayoutListApi: 'services/AdminApp/ClientPayoutService/GetPayoutList',
	GetClientPendingCountApi:
		'services/AdminApp/ClientPayoutService/SearchAllTabCount',

	GetClientPendingPayoutCountApi:
		'services/AdminApp/ClientPayoutService/GetClientPendingPayoutCount',
	GetClientProfilePayoutApi:
		'services/AdminApp/ClientPayoutService/GetClientPayoutTransactionList',
	GetClientPayoutDetailsApi: 'services/AdminApp/ClientPayoutService/GetDetails',
	GetServiceProvidetPayoutDetailsApi:
		'services/AdminApp/ServiceProviderPayoutService/GetDetails',
	AcceptSPPendingPayoutApi:
		'services/AdminApp/ServiceProviderPayoutService/AcceptSPPendingPayout',
	AcceptClientPayoutApi:
		'services/AdminApp/ClientPayoutService/AcceptPayoutRequest',
	RejectClientPayoutApi:
		'services/AdminApp/ClientPayoutService/RejectPayoutRequest',
	UploadclientPayoutImageApi: 'Image/UploadPayoutImage',
	UploadSpImageApi: 'Image/UploadPayoutImage',
	GetSPPayoutListApi:
		'services/AdminApp/ServiceProviderPayoutService/GetPayoutList',
	SearchAllSPPayoutTabCounttApi:
		'services/AdminApp/ServiceProviderPayoutService/SearchAllTabCount',
	GetBranchPayoutMethodsApi:
		'services/AdminApp/ServiceProviderPayoutService/GetBranchPayoutMethods',
	GetSPPayoutTransactionListApi:
		'services/AdminApp/ServiceProviderPayoutService/GetSPPayoutTransactionList',
	GetSPPendingPayoutCountApi:
		'services/AdminApp/ServiceProviderPayoutService/GetSPPendingPayoutCount',

	// SETTINGS
	UpdatePayoutDate:
		'services/AdminApp/PayoutSettingService/UpdatePayoutSetting',
	getPayoutDate: 'services/AdminApp/PayoutSettingService/GetPayoutSetting',

	//PRODUCTS
	getProductListApi: 'services/AdminApp/Product/GetAllProducts',
	getProductDetailsApi: 'services/AdminApp/Product/GetProductDetails',
	uploadProductPhotoApi: 'Image/UploadProductImage',
	getSubcategoryListApi:
		'services/AdminApp/ProductCategory/GetSubCategoriesList',
	getBrandListApi: 'services/AdminApp/ProductBrand/GetAll',
	getRelatedProduct: 'services/AdminApp/Product/GetProductsByKeyword',
	addProductApi: 'services/AdminApp/Product/CreateProduct',
	isSkuUnique: 'services/AdminApp/Product/IsSKUNumberExistsBefore',

	getActiveCountApi: 'services/AdminApp/Product/GetActiveAndInActiveCount',
	getProductVariantTypeApi: 'services/AdminApp/Product/GetProductVariantType',
	updateProductDetailsApi: 'services/AdminApp/Product/UpdateProduct',
	updateProductStatusApi:
		'services/AdminApp/Product/ChangeProductActivationStatus',
	updateProductVariantStatusApi:
		'services/AdminApp/Product/ChangeProductVariantActivationStatus',
	updateProductImages: 'services/AdminApp/Product/UpdateProductImages',
	updateProductVariantImages: 'services/AdminApp/Product/UpdateProductImages',
	exportProductsToExcelApi:
		'services/AdminApp/Product/ExportProductsToExcelFile',
	// order
	getOrderListApi: 'services/AdminApp/Orders/GetAllOrders',
	getOrderNewCountApi: 'services/AdminApp/Orders/GetOrderStatusCounts',
	getOrderDetailsApi: 'services/AdminApp/Orders/GetOrderDetails',
	changeOrderStatusApi: 'services/AdminApp/Orders/ChangeOrderStatus',
	GetAllCitiesApi: 'services/AdminApp/Orders/GetAllCities',
	GetAllAreasApi: 'services/AdminApp/Orders/GetAllAreas',
	exportOrdersToExcelApi: 'services/AdminApp/Orders/ExportOrdersToExcelFile',
	// PRODUCT PROMO CODES API
	createProductPromoCodeApi: 'services/AdminApp/PromoCode/CreatePromoCode',
	getFilteredPromoCodeProductsApi:
		'services/AdminApp/PromoCode/GetAllProductsForPromoCode',
	getProductPromocodeListApi: 'services/AdminApp/PromoCode/GetAllPromoCodes',
	checkCodeUniqueApi: 'services/AdminApp/PromoCode/GetIsPromoCodesByCodeExist',
	getPromocodeDetailsApi: 'services/AdminApp/PromoCode/GetPromoCodeDetailById',
	deletePromocodeApi: 'services/AdminApp/PromoCode/DeletePromoCode',
	editPromocodeApi: 'services/AdminApp/PromoCode/UpdatePromoCode',
	activateDeactivatePromocodeApi:
		'services/AdminApp/PromoCode/UpdatePromoCodeActiv',
}
