import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss'],
})
export class CustomDatepickerComponent implements OnInit, OnChanges {
  @Input() label: string = ''
  @Input() required: boolean = false
  @Input() defaultValue: string = ''
  @Output() dateValue = new EventEmitter()
  date = new FormControl(
    this.defaultValue || '',
    this.required ? Validators.required : null,
  )

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.date = new FormControl(
      this.defaultValue || '',
      this.required ? Validators.required : null,
    )
  }
}
